@media screen and (max-width: 1280px) {
	.logo-home a img {
    width: 200px;
    padding: 15px 0;
}
.box-gallery-list {
  width: 150px;
  height: 150px;
}
.dp-menu button{
  font-size: 12px;
}
ul.menu-top li a{
  font-size: 12px;
}
}
@media screen and (max-width: 1024px) {

  .dp-menu button,ul.menu-top li a{
    font-size: 9px;
    padding: 0 5px;
  }
  .logo-home a img {
    width: 120px;
    padding: 30px 0;
}
.box-gallery-list {
  width: 100px;
  height: 100px;
}
.box-gallery-list-a {
  width: 50px;
  height: 50px;
}
	
}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 490px) {
    .wrapper{
        padding: 0px;
    }
    .box-index{
        margin-top: 75px;
    }
    .banner-custom .alice-carousel__prev-btn{
        left: 5px;
    }
    .banner-custom .alice-carousel__next-btn{
        right: 5px;
    }
    .banner-custom .sorn-left,.banner-custom .sorn-right{
        height: 25px;
    }
    .banner-custom .alice-carousel__dots {
        bottom: 0px;
        left: 8px;
    }
    .banner-custom .alice-carousel__dots-item:not(.__custom) {
        width: 20px !important;
        height: 4px !important;
    }
    .form-control{
        border-bottom: 1px solid #1C184A;
    }
    .btn-login{
        background-color: #1C184A;
        color: #fff !important;
        padding: 5px 30px !important;
        display: block;
        width: 100%;
    }
    .box-num-time{
        position: relative;
        bottom: 0px;
        margin-top: 15px;
    }
    .bg-menu-m{
        top: 0;
    }
    .box-news{
        margin: 30px 0;
      }
      .box-section-youtube{
        margin-top: 0px;
      }
      .present-height{
        height: 220px;
      }
      .box-youtube{
        bottom: 100px;
      }
      .box-absolute-res{
        position:relative !important;
      }
      .absolute-center-res{
        top: unset;
        transform: unset;
      }
      .box-footer {
        padding: 30px 15px 15px;
      }
      .overlay {
        height: 35%;
        opacity: 1;
    }
    .overlay-text{
        bottom: 15px;
    }
    .logo-home a img {
        width: 180px;
    }
    .box-absolute-bottom{
        position: relative;
    }

    .login-menu button{
        background-color: #1C184A;
        color: #fff;
        width: 100%;
      }
      .login-menu button:hover,.login-menu button:focus,.login-menu button:active:focus{
        background-color: #1C184A;
        color: #fff;
      }
      .overlay-text {
        font-size: 14px;
        padding: 0 15px;
      }
      .topic-news {
        font-size: 22px;
    }
    .rotate-90{
      left: -10px;
    }
    .margin-res-0{
      margin-top: 0 !important;
    }
    .box-gallery-list {
      width: 60px;
      height: 60px;
    }
   
}