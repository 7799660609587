.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* @import url("https://fast.fonts.net/lt/1.css?apiType=css&c=0e62fe1c-ab1f-4c5e-accc-72dc326c1e9b&fontids=1475944,1475950,1475956,1475962,1475968,1475974,1475980,1475986,1475998,1476004,1476010,1476016,1476022,1476028,1476034,1476040,1476046,1476052,1476058,1571205,1571211,1571217,1571223,1571229,1571235,5658344"); */
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@100;200;300;400;500;600&display=swap');
@import url("./assets/font/stylesheet.css");

body,
html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  /*	font-size: 16px;*/
}

body {
  font-family: "Prompt", sans-serif !important;
  background: #f8f8f8;
  color: #242424;
  font-weight: 500;
  line-height: 1.3;
}
header {
  padding: 0 0px;
  z-index: 9;
}
.box-content {
  padding: 0 90px;
}
.wrapper {
  /* padding: 0 60px; */
  min-height: 90vh;
  overflow: hidden;
  width: 100%;
  max-width: 1920px;
}
.color-white {
  color: #fff !important;
}
.color-red {
  color: #e97372;
}
.color-green {
  color: #11819f !important;
}
.color-gray {
  color: #999999;
}
.color-light-blue {
  color: #60A6DB;
}
.color-black {
  color: #000;
}
.color-brown{
  color: #835D3E;
}
.color-brown-2{
  color: #CF9B5B;
}
.loding-page {
  background-color: #abe6cd;
}
.introl-page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
textarea.form-control::placeholder,
textarea.form-control::-webkit-input-placeholder,
textarea.form-control:-ms-input-placeholder {
  color: #fff !important;
}
/* hr{
  background-color: #F0F0F0;
} */
.topic {
  font-size: 20px;
}
.content {
  font-size: 14px;
  font-weight: 400;
}
.thumbnail {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
}
.border-top {
  border-top: 1px solid #dedede;
}
.font-10 {
  font-size: 10px !important;
}
.font-11 {
  font-size: 11px !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-18 {
  font-size: 18px !important;
}
.font-20 {
  font-size: 20px !important;
}
.font-22 {
  font-size: 22px !important;
}
.font-24 {
  font-size: 24px !important;
}
.font-32 {
  font-size: 32px !important;
}

.weight-100 {
  font-weight: 100;
}
.weight-200 {
  font-weight: 200;
}
.weight-300 {
  font-weight: 300;
}
.weight-400 {
  font-weight: 400;
}
.weight-500 {
  font-weight: 500;
}
.weight-600 {
  font-weight: 600;
}
.weight-700 {
  font-weight: 700;
}

.rounded-4 {
  border-radius: 12px;
}



/* body {
  overflow: hidden;
  margin: 0;
  padding: 0;
} */
.react-tiger-transition--container {
  position: unset !important;
  width: 100%;
  height: 100%;
  -webkit-perspective: unset !important;
  perspective: unset !important;
  /* overflow: hidden; */
}
/* .react-tiger-transition--container {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-perspective: 1200px;
  perspective: 1200px;
  overflow: hidden;
} */
.react-tiger-transition--route {
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  position: absolute;
  visibility: visible;
  /* overflow: hidden; */
  /* -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0); */
  /* -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; */
}
.react-tiger-transition--screen {
  width: 100%;
  height: 100%;
  /* position: relative; */
  overflow-y: scroll;
}
.bg-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 1) 25%,
    rgba(0, 0, 0, 0) 100%,
    rgba(0, 212, 255, 1) 100%
  ) !important;
}
.bg-gray{
  background-color: #F3F3F3;
}
.bg-gray-modal{
  background-color: #2B2B2B !important;
}
.btn-close{
  opacity: 1 !important;
  transition: all .3s ease;
}
.btn-close:hover{
  opacity: .5 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid,
.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: unset;
  padding-right: 10px;
}
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}
input.form-control::placeholder {
  color: #999999;
  transition: all 0.5s ease;
}

.btn-green {
  background-color: #11819f;
  color: #fff;
  transition: all 0.5s ease;
  padding: 8px 0;
}
.btn-gray {
  background-color: #f0f0f0;
  color: #11819f;
  transition: all 0.5s ease;
  padding: 8px 0;
}

.btn-login {
  background-color: #f0f0f0;
  color: #000;
  /* font-size: 0.8em; */
  transition: all 0.5s ease;
  padding: 3px 35px;
  text-decoration: none;
  border: none;
}
.btn-signup {
  background-color: #11819f;
  color: #fff;
  font-size: 0.8em;
  transition: all 0.5s ease;
}
.btn-cancel {
  background-color: #f0f0f0;
  color: #e97372;
  font-size: 0.8em;
  transition: all 0.5s ease;
}

.btn-green:hover,
.btn-gray:hover,
.btn-login:hover,
.btn-signup:hover,
.btn-cancel:hover {
  background-color: #959595;
  color: #fff;
  transition: all 0.5s ease;
}
.date-name-profile {
  font-size: 12px;
  font-weight: 300;
  transition: all 0.5s ease;
}
.btn-link-default {
  text-decoration: none;
  color: #000;
  font-weight: 300;
}


