.ex-checkbox,
.ex-radio {
  position: relative;
  margin: 0;
  font-size: 14px;
}

.ex-checkbox label,
.ex-radio span {
  padding-left: 30px;
  line-height: 140%;
  font-weight: normal;
  display: inline-block;
  position: relative;
  height: 22px;
  margin: 0;
}

.ex-checkbox input[type="checkbox"],
.ex-radio input[type="radio"] {
  margin: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 20px;
  z-index: 10;
  position: absolute;
}

.ex-checkbox input[type="checkbox"] + label::before {
  border: 1px solid #1C184A;
  width: 18px;
  height: 18px;
  content: "";
  position: absolute;
  left: 0;
  background-color: #fff;
  border-radius: 0px;
}

/* .ex-checkbox input[type="checkbox"]:hover + label::before {
  border: 2px solid #abe6cd;
} */

.ex-checkbox input[type="checkbox"]:focus + label::before {
  box-shadow: 0 0 1px #abe6cd;
  outline: none;
}

.ex-checkbox input[type="checkbox"]:checked + label::before {
  /* border: 1px solid #abe6cd; */
  background: url("../img/took2.svg") no-repeat center center;
  background-size: 16px 16px;
}

/* .ex-checkbox input[type="checkbox"]:checked:hover + label::before {
  background-size: 30px 30px;
} */

.ex-checkbox input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

.ex-checkbox input[type="checkbox"]:disabled + label {
  color: #bcbcbc;
  cursor: not-allowed;
}

.ex-checkbox input[type="checkbox"]:disabled:checked + label::before {
  border: 1px solid transparent;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBmaWxsPSIjYmNiY2JjIiBkPSJNMTguOSA4LjhsLTguNyA4LjdjLS4xLjEtLjIuMS0uMyAwbC00LjktNC45Yy0uMS0uMS0uMS0uMiAwLS4zbDEuMi0xLjJjLjEtLjEuMi0uMS4zIDBsMy42IDMuNiA3LjMtNy4zYy4xLS4xLjItLjEuMyAwbDEuMiAxLjJjLjEgMCAuMS4xIDAgLjJ6IiAvPjwvc3ZnPg==")
    no-repeat center center;
}

.ex-checkbox input[type="checkbox"]:disabled + label::before {
  border: 1px solid #bcbcbc;
}

.ex-radio input[type="radio"] + span::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  border: 1px solid #9f9f9f;
  border-radius: 50%;
  background-color: #fff;
  transition: border 0.3s ease-in-out;
}

.ex-radio input[type="radio"]:hover + span::before {
  border: 2px solid #2E384D;
}

.ex-radio input[type="radio"]:focus + span::before {
  box-shadow: 0 0 1px #2E384D;
  outline: none;
}

.ex-radio input[type="radio"]:checked + span::before,
.ex-radio input[type="radio"]:checked:hover + span::before {
  border: 1px solid #2E384D;
}

.ex-radio input[type="radio"] + span::after {
  position: absolute;
  content: " ";
  width: 12px;
  height: 12px;
  left: 4px;
  top: 4px;
  border-radius: 50%;
  background-color: #ffffff;
  transform: scale(0, 0);
  transition: transform 0.3s ease-in-out;
}

.ex-radio input[type="radio"]:checked + span::after {
  background-color: #2E384D;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  transform: scale(1, 1);
}

.ex-radio input[type="radio"]:disabled {
  cursor: not-allowed;
}

.ex-radio input[type="radio"]:disabled + span {
  color: #bcbcbc;
  cursor: not-allowed;
}

.ex-radio input[type="radio"]:disabled + span::before,
.ex-radio input[type="radio"]:checked:disabled + span::before {
  border: 1px solid #bcbcbc;
}

.ex-radio input[type="radio"]:checked:disabled + span::after {
  background-color: #bcbcbc;
}

.ex-inputfield {
  position: relative;
  margin: 16px 0;
}

.ex-inputfield input::-ms-clear,
.ex-inputfield input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.ex-inputfield input[type="text"] {
  -webkit-appearance: none;
  height: 24px;
  padding: 0 8px;
  border: 1px solid #9f9f9f;
  font-size: 14px;
  border-radius: 0;
}

.ex-inputfield input[type="text"].ex-inputfield--large {
  height: 40px;
  padding: 0 16px;
  font-size: 16px;
}

.ex-inputfield input[type="text"]:disabled {
  background-color: #f0f0f0;
  border: 1px solid #bcbcbc;
  color: #757575;
  cursor: not-allowed;
  opacity: 1;
  /*used for safari as it changes it by default to 0.4*/
}

.ex-inputfield input[type="text"]:focus {
  box-shadow: 0 0 1px #abe6cd;
  outline: none;
}

.ex-inputfield input::-webkit-input-placeholder {
  color: #bcbcbc;
}

.ex-inputfield input[type="text"]:-ms-input-placeholder {
  color: #bcbcbc;
}

.ex-inputfield input::-moz-placeholder {
  color: #bcbcbc;
  opacity: 1;
}



.ex-checkbox2,
.ex-radio2 {
  position: relative;
  margin: 0;
  font-size: 14px;
}

.ex-checkbox2 label,
.ex-radio2 span {
  padding-left: 0px;
  line-height: 140%;
  font-weight: normal;
  display: inline-block;
  position: relative;
  /* height: 18px; */
  margin: 0;
  width: 100%;
  background-color: #F0F0F0;
  color: #88B8A4;
  padding: 30px 15px;
  border-radius: 6px;
}

.ex-checkbox2 input[type="checkbox"],
.ex-radio2 input[type="radio"] {
  margin: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 80px;
  z-index: 10;
  left: 0;
  position: absolute;
}

.ex-checkbox2 input[type="checkbox"] + label::before {
  /* border: 1px solid #abe6cd; */
  width: 23px;
  height: 23px;
  content: "";
  position: absolute;
  right: 15px;
  background-color: #fff;
}

/* .ex-checkbox2 input[type="checkbox"]:hover + label::before {
  border: 2px solid #abe6cd;
} */

.ex-checkbox2 input[type="checkbox"]:focus + label::before {
  /* box-shadow: 0 0 1px #abe6cd; */
  outline: none;
}

.ex-checkbox2 input[type="checkbox"]:checked + label {
  background-color: #CCF0E1;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}
.ex-checkbox2 input[type="checkbox"]:checked + label::before {
  /* border: 1px solid #abe6cd; */
  background: url("../img/took2.svg") no-repeat center center;
  background-size: 24px 24px;
}

/* .ex-checkbox2 input[type="checkbox"]:checked:hover + label::before {
  background-size: 30px 30px;
} */

.ex-checkbox2 input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

.ex-checkbox2 input[type="checkbox"]:disabled + label {
  color: #bcbcbc;
  cursor: not-allowed;
}

.ex-checkbox2 input[type="checkbox"]:disabled:checked + label::before {
  border: 1px solid transparent;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBmaWxsPSIjYmNiY2JjIiBkPSJNMTguOSA4LjhsLTguNyA4LjdjLS4xLjEtLjIuMS0uMyAwbC00LjktNC45Yy0uMS0uMS0uMS0uMiAwLS4zbDEuMi0xLjJjLjEtLjEuMi0uMS4zIDBsMy42IDMuNiA3LjMtNy4zYy4xLS4xLjItLjEuMyAwbDEuMiAxLjJjLjEgMCAuMS4xIDAgLjJ6IiAvPjwvc3ZnPg==")
    no-repeat center center;
}

.ex-checkbox2 input[type="checkbox"]:disabled + label::before {
  border: 1px solid #bcbcbc;
}


.ex-checkbox3,
.ex-radio3 {
  position: relative;
  margin: 0;
  font-size: 14px;
}

.ex-checkbox3 label,
.ex-radio3 span {
  padding-left: 0px;
  line-height: 140%;
  font-weight: normal;
  display: inline-block;
  position: relative;
  /* height: 18px; */
  margin: 0;
  width: 100%;
  /* background-color: #F0F0F0; */
  color: #88B8A4;
  padding: 30px 15px;
  border-radius: 6px;
}

.ex-checkbox3 input[type="checkbox"],
.ex-radio3 input[type="radio"] {
  margin: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 150px;
  z-index: 10;
  left: 0;
  position: absolute;
}

.ex-checkbox3 input[type="checkbox"] + label::before {
  /* border: 1px solid #abe6cd; */
  width: 23px;
  height: 23px;
  content: "";
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  border-radius: 3px;
}

/* .ex-checkbox3 input[type="checkbox"]:hover + label::before {
  border: 2px solid #abe6cd;
} */

.ex-checkbox3 input[type="checkbox"]:focus + label::before {
  /* box-shadow: 0 0 1px #abe6cd; */
  outline: none;
}

.ex-checkbox3 input[type="checkbox"]:checked + label {
  /* background-color: #CCF0E1; */
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}
.ex-checkbox3 input[type="checkbox"]:checked + label::before {
  /* border: 1px solid #abe6cd; */
  background: url("../img/took2.svg") no-repeat center center;
  background-size: 24px 24px;
}

/* .ex-checkbox2 input[type="checkbox"]:checked:hover + label::before {
  background-size: 30px 30px;
} */

.ex-checkbox3 input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

.ex-checkbox3 input[type="checkbox"]:disabled + label {
  color: #bcbcbc;
  cursor: not-allowed;
}

.ex-checkbox3 input[type="checkbox"]:disabled:checked + label::before {
  border: 1px solid transparent;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBmaWxsPSIjYmNiY2JjIiBkPSJNMTguOSA4LjhsLTguNyA4LjdjLS4xLjEtLjIuMS0uMyAwbC00LjktNC45Yy0uMS0uMS0uMS0uMiAwLS4zbDEuMi0xLjJjLjEtLjEuMi0uMS4zIDBsMy42IDMuNiA3LjMtNy4zYy4xLS4xLjItLjEuMyAwbDEuMiAxLjJjLjEgMCAuMS4xIDAgLjJ6IiAvPjwvc3ZnPg==")
    no-repeat center center;
}

.ex-checkbox3 input[type="checkbox"]:disabled + label::before {
  border: 1px solid #bcbcbc;
}


.ex-checkbox4,
.ex-radio4 {
  position: relative;
  margin: 0;
  font-size: 16px;
}
.ex-checkbox4 label{
  font-size: 14px;
  padding-left: 0px;
  line-height: 140%;
  font-weight: 400;
  display: inline-block;
  position: relative;
  /* height: 18px; */
  margin: 0;
  width: 100%;
  /* background-color: #F0F0F0; */
  /* color: #88B8A4; */
  /* padding: 30px 15px; */
  border-radius: 6px;
}
.ex-radio4 span {
  padding-left: 0px;
  line-height: 140%;
  font-weight: 500;
  display: inline-block;
  position: relative;
  /* height: 18px; */
  margin: 0;
  width: 100%;
  /* background-color: #F0F0F0; */
  /* color: #88B8A4; */
  /* padding: 30px 15px; */
  border-radius: 6px;
}

.ex-checkbox4 input[type="checkbox"]{
  margin: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 20px;
  z-index: 10;
  left: 0;
  position: absolute;
}
.ex-radio4 input[type="radio"] {
  margin: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 22px;
  z-index: 10;
  left: 0;
  position: absolute;
}
.ex-checkbox4 input[type="checkbox"] + label::before{
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
  left: 0px;
  background-color: #F0F0F0;
  border-radius: 3px;
}
.ex-radio4 input[type="radio"] + label::before {
  border: 1px solid #1C184A;
  width: 18px;
  height: 18px;
  content: "";
  position: absolute;
  left: 0px;
  /* background-color: #F0F0F0; */
}

/* .ex-checkbox4 input[type="checkbox"]:hover + label::before {
  border: 2px solid #abe6cd;
} */

.ex-checkbox4 input[type="checkbox"]:focus + label::before,.ex-radio4 input[type="radio"]:focus + label::before {
  /* box-shadow: 0 0 1px #abe6cd; */
  outline: none;
}

/* .ex-checkbox4 input[type="checkbox"]:checked + label {
  background-color: #CCF0E1;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
} */
.ex-checkbox4 input[type="checkbox"]:checked + label::before,.ex-radio4 input[type="radio"]:checked + label::before {
  /* border: 1px solid #abe6cd; */
  background: url("../img/took2.svg") no-repeat center center;
  background-size: 16px 16px;
}

/* .ex-checkbox4 input[type="checkbox"]:checked:hover + label::before {
  background-size: 30px 30px;
} */

.ex-checkbox4 input[type="checkbox"]:disabled,.ex-radio4 input[type="radio"]:disabled {
  cursor: not-allowed;
}

.ex-checkbox4 input[type="checkbox"]:disabled + label,.ex-radio4 input[type="radio"]:disabled + label {
  color: #bcbcbc;
  cursor: not-allowed;
}

.ex-checkbox4 input[type="checkbox"]:disabled:checked + label::before,.ex-radio4 input[type="radio"]:disabled:checked + label::before {
  border: 1px solid transparent;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBmaWxsPSIjYmNiY2JjIiBkPSJNMTguOSA4LjhsLTguNyA4LjdjLS4xLjEtLjIuMS0uMyAwbC00LjktNC45Yy0uMS0uMS0uMS0uMiAwLS4zbDEuMi0xLjJjLjEtLjEuMi0uMS4zIDBsMy42IDMuNiA3LjMtNy4zYy4xLS4xLjItLjEuMyAwbDEuMiAxLjJjLjEgMCAuMS4xIDAgLjJ6IiAvPjwvc3ZnPg==")
    no-repeat center center;
}

.ex-checkbox4 input[type="checkbox"]:disabled + label::before,.ex-radio4 input[type="radio"]:disabled + label::before {
  border: 1px solid #bcbcbc;
}



.forform {
  display: block;
  position: relative;
  /* padding-left: 35px; */
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.forform input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.forform .checkmark {
  /* position: absolute; */
  display: block;
  width: 100%;
  padding: 12px 12px;
  font-size: 14px;
  text-align: center;
  border: 1px solid #F0F0F0;
  background-color: #F0F0F0;
  color: #88B8A4;
  border-radius: 4px;
  font-weight: 300;
  margin-bottom: 8px;
}

.forform input:checked~.checkmark {
  background-color: #88B8A4;
  color: #fff;
}

.forform input:disabled {
  opacity: 0
}

.forform input:disabled~.checkmark {
  color: rgba(0, 0, 0, .26);
  cursor: no-drop;
}

.forform2 {
  display: block;
  position: relative;
  /* padding-left: 35px; */
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.forform2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.forform2 .checkmark {
  /* position: absolute; */
  display: block;
  width: 100%;
  /* padding: 12px 12px; */
  font-size: 16px;
  text-align: center;
  /* border: 1px solid #F0F0F0; */
  /* background-color: #F0F0F0; */
  color: #D8D8D8;
  font-weight: 400;
  border-radius: 4px;
  margin-bottom: 8px;
}

.forform2 .checkmark img{
  filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(
    207deg) brightness(95%) contrast(96%);
}

.forform2 input:checked~.checkmark {
  /* background-color: #88B8A4; */
  color: #242424;
  font-weight: 500;
}
.forform2 input:checked~.checkmark img{
  filter: unset;
}

.forform2 input:disabled {
  opacity: 0
}

.forform2 input:disabled~.checkmark {
  color: rgba(0, 0, 0, .26);
  cursor: no-drop;
}



.item-renderer{
  position: relative;
  margin: 0;
  font-size: 14px;
}

.item-renderer span {
  padding-left: 30px;
  line-height: 140%;
  font-weight: normal;
  display: inline-block;
  position: relative;
  height: 22px;
  margin: 0;
}

.item-renderer input[type="checkbox"]{
  margin: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 23px;
  z-index: 10;
  position: absolute;
}

.item-renderer input[type="checkbox"] + span::before {
  border: 1px solid #DDDEDD;
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
  left: 0;
  background-color: #fff;
  border-radius: 5px;
}

/* .ex-checkbox input[type="checkbox"]:hover + label::before {
  border: 2px solid #abe6cd;
} */

.item-renderer input[type="checkbox"]:focus + span::before {
  box-shadow: 0 0 1px #abe6cd;
  outline: none;
}

.item-renderer input[type="checkbox"]:checked + span::before {
  border: 1px solid #abe6cd;
  background: url("../img/took2.svg") no-repeat center center;
  background-size: 20px 20px;
}

/* .ex-checkbox input[type="checkbox"]:checked:hover + label::before {
  background-size: 30px 30px;
} */

.item-renderer input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

.item-renderer input[type="checkbox"]:disabled + span {
  color: #bcbcbc;
  cursor: not-allowed;
}

.item-renderer input[type="checkbox"]:disabled:checked + span::before {
  border: 1px solid transparent;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBmaWxsPSIjYmNiY2JjIiBkPSJNMTguOSA4LjhsLTguNyA4LjdjLS4xLjEtLjIuMS0uMyAwbC00LjktNC45Yy0uMS0uMS0uMS0uMiAwLS4zbDEuMi0xLjJjLjEtLjEuMi0uMS4zIDBsMy42IDMuNiA3LjMtNy4zYy4xLS4xLjItLjEuMyAwbDEuMiAxLjJjLjEgMCAuMS4xIDAgLjJ6IiAvPjwvc3ZnPg==")
    no-repeat center center;
}

.item-renderer input[type="checkbox"]:disabled + span::before {
  border: 1px solid #bcbcbc;
}
.rmsc .select-item{
  padding: 2px 10px !important;
}
.rmsc .options{
  padding: 10px 0;
}
.rmsc .select-item.selected{
  background: unset !important;
}

.accordion-button:focus{
  border-color: #E6E6E6;
  box-shadow: unset;
}
.accordion-button:not(.collapsed){
  color: unset;
  background-color: unset;
  box-shadow: unset;
}
/* sort-down.svg */
/* .accordion-button:not(.collapsed)::after,.accordion-button::after{
  background-image: url("../img/sort-down.svg");
  background-size: 12px;
  height: 8px;
  width: 12px;
}
.accordion-item{
  border-top: unset;
  border-left: unset;
  border-right: unset;
  border-bottom: 1px solid #E6E6E6;
}
.accordion-item:last-child{
  border-bottom: unset;
}
.accordion-item .accordion-button{
  padding: 20px 0;
} */


.ex-checkbox-chat,
.ex-radio-chat {
  position: relative;
  margin: 0;
  font-size: 14px;
}

.ex-checkbox-chat label,
.ex-radio-chat span {
  padding-left: 30px;
  line-height: 140%;
  font-weight: normal;
  display: inline-block;
  position: relative;
  height: 22px;
  margin: 0;
}

.ex-checkbox-chat input[type="checkbox"],
.ex-radio-chat input[type="radio"] {
  margin: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 38px;
  z-index: 10;
  position: absolute;
}

.ex-checkbox-chat input[type="checkbox"] + label::before {
  border: 1px solid #DDDEDD;
  width: 23px;
  height: 23px;
  content: "";
  position: absolute;
  left: 0;
  background-color: #fff;
  border-radius: 5px;
  top: 10px;
}
.ex-checkbox-chat input[type="checkbox"]:focus + label::before {
  box-shadow: 0 0 1px #abe6cd;
  outline: none;
}

.ex-checkbox-chat input[type="checkbox"]:checked + label::before {
  border: 1px solid #abe6cd;
  background: url("../img/took2.svg") no-repeat center center;
  background-size: 21px 21px;
}

.ex-checkbox-chat input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

.ex-checkbox-chat input[type="checkbox"]:disabled + label {
  color: #bcbcbc;
  cursor: not-allowed;
}

.ex-checkbox-chat input[type="checkbox"]:disabled:checked + label::before {
  border: 1px solid transparent;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBmaWxsPSIjYmNiY2JjIiBkPSJNMTguOSA4LjhsLTguNyA4LjdjLS4xLjEtLjIuMS0uMyAwbC00LjktNC45Yy0uMS0uMS0uMS0uMiAwLS4zbDEuMi0xLjJjLjEtLjEuMi0uMS4zIDBsMy42IDMuNiA3LjMtNy4zYy4xLS4xLjItLjEuMyAwbDEuMiAxLjJjLjEgMCAuMS4xIDAgLjJ6IiAvPjwvc3ZnPg==")
    no-repeat center center;
}

.ex-checkbox-chat input[type="checkbox"]:disabled + label::before {
  border: 1px solid #bcbcbc;
}


.form-switch .form-check-input {
  width: 40px;
}
.form-check-input {
  height: 22px;
  cursor: pointer;
}
.form-check-input:focus {
  border-color: #EAEAEA;
  box-shadow: unset;
}
.form-switch .form-check-input:focus {
  background-color: #EAEAEA;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-color: #008319;
  border-color: #008319;
  border: none;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
}

fieldset {
  margin: 0;
  box-sizing: border-box;
  display: block;
  border: none;
  min-width: 0;
  background-color: #FFF;
}

body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}

/* TOGGLE STYLING */
.toggle {
  margin: 0 0 1.5rem;
  box-sizing: border-box;
  font-size: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  box-shadow: -10px -5px 8px 4px rgb(173, 173,173,.7);
}
.toggle input {
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
}
.toggle input + label {
  margin: 0;
  padding: 0.75rem 2rem;
  box-sizing: border-box;
  position: relative;
  width: 50%;
    display: inline-block;
  /* border: solid 1px #DDD; */
 
  background-color: #FFF;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  /* box-shadow: 0 0 0 rgba(255, 255, 255, 0); */
  transition: border-color 0.15s ease-out, color 0.25s ease-out, background-color 0.15s ease-out, box-shadow 0.15s ease-out;
  /* ADD THESE PROPERTIES TO SWITCH FROM AUTO WIDTH TO FULL WIDTH */
  /*flex: 0 0 50%; display: flex; justify-content: center; align-items: center;*/
  /* ----- */
}
.toggle input + label:first-of-type {
  /* border-radius: 6px 0 0 6px; */
  border-right: none;
  border-left: none;
  border-top: none;
}
.toggle input + label:last-of-type {
  /* border-radius: 0 6px 6px 0; */
  border-left: none;
  border-right: none;
  border-top: none;
}
.toggle input:hover + label {
  border-color: #213140;
}
.toggle input:checked + label {
  /* background-color: #4B9DEA; */
  /* color: #FFF; */
  /* box-shadow: 0 0 10px rgba(102, 179, 251, 0.5); */
  border-bottom:3px solid #2E384D;
  z-index: 1;
}
.toggle input:focus + label {
  /* outline: dotted 1px #CCC; */
  outline-offset: 0.45rem;
}
.a-select-answer{
  color: #000000;
  text-decoration: none;
}
.a-select-answer:hover{
  color: #fff;
}
.a-select-answer:hover .bg-select-answer{
  background-color: #1C184A;
}
.a-select-answer:hover .bg-select-answer img{
  filter: brightness(0) invert(1);
}
.bg-select-answer img{
  transition: all .3s ease;
}
.bg-select-answer{
  background-color: #fff;
  padding: 15px;
  transition: all .3s ease;
}
@media (max-width: 800px) {
  .toggle input + label {
    padding: 0.75rem 0.25rem;
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

