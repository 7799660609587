@import url(https://fonts.googleapis.com/css2?family=Prompt:wght@100;200;300;400;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
    font-family: 'Bebas';
    src: url(/static/media/Bebas-Regular.4bf3b090.woff2) format('woff2'),
        url(/static/media/Bebas-Regular.f64b1bcf.woff) format('woff'),
        url(/static/media/Bebas-Regular.6110ae9e.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* @import url("https://fast.fonts.net/lt/1.css?apiType=css&c=0e62fe1c-ab1f-4c5e-accc-72dc326c1e9b&fontids=1475944,1475950,1475956,1475962,1475968,1475974,1475980,1475986,1475998,1476004,1476010,1476016,1476022,1476028,1476034,1476040,1476046,1476052,1476058,1571205,1571211,1571217,1571223,1571229,1571235,5658344"); */

body,
html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  /*	font-size: 16px;*/
}

body {
  font-family: "Prompt", sans-serif !important;
  background: #f8f8f8;
  color: #242424;
  font-weight: 500;
  line-height: 1.3;
}
header {
  padding: 0 0px;
  z-index: 9;
}
.box-content {
  padding: 0 90px;
}
.wrapper {
  /* padding: 0 60px; */
  min-height: 90vh;
  overflow: hidden;
  width: 100%;
  max-width: 1920px;
}
.color-white {
  color: #fff !important;
}
.color-red {
  color: #e97372;
}
.color-green {
  color: #11819f !important;
}
.color-gray {
  color: #999999;
}
.color-light-blue {
  color: #60A6DB;
}
.color-black {
  color: #000;
}
.color-brown{
  color: #835D3E;
}
.color-brown-2{
  color: #CF9B5B;
}
.loding-page {
  background-color: #abe6cd;
}
.introl-page {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
textarea.form-control::-webkit-input-placeholder {
  color: #fff !important;
}
textarea.form-control::placeholder,
textarea.form-control::-webkit-input-placeholder,
textarea.form-control:-ms-input-placeholder {
  color: #fff !important;
}
/* hr{
  background-color: #F0F0F0;
} */
.topic {
  font-size: 20px;
}
.content {
  font-size: 14px;
  font-weight: 400;
}
.thumbnail {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
}
.border-top {
  border-top: 1px solid #dedede;
}
.font-10 {
  font-size: 10px !important;
}
.font-11 {
  font-size: 11px !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-18 {
  font-size: 18px !important;
}
.font-20 {
  font-size: 20px !important;
}
.font-22 {
  font-size: 22px !important;
}
.font-24 {
  font-size: 24px !important;
}
.font-32 {
  font-size: 32px !important;
}

.weight-100 {
  font-weight: 100;
}
.weight-200 {
  font-weight: 200;
}
.weight-300 {
  font-weight: 300;
}
.weight-400 {
  font-weight: 400;
}
.weight-500 {
  font-weight: 500;
}
.weight-600 {
  font-weight: 600;
}
.weight-700 {
  font-weight: 700;
}

.rounded-4 {
  border-radius: 12px;
}



/* body {
  overflow: hidden;
  margin: 0;
  padding: 0;
} */
.react-tiger-transition--container {
  position: unset !important;
  width: 100%;
  height: 100%;
  -webkit-perspective: unset !important;
  perspective: unset !important;
  /* overflow: hidden; */
}
/* .react-tiger-transition--container {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-perspective: 1200px;
  perspective: 1200px;
  overflow: hidden;
} */
.react-tiger-transition--route {
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  position: absolute;
  visibility: visible;
  /* overflow: hidden; */
  /* -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0); */
  /* -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; */
}
.react-tiger-transition--screen {
  width: 100%;
  height: 100%;
  /* position: relative; */
  overflow-y: scroll;
}
.bg-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 1) 25%,
    rgba(0, 0, 0, 0) 100%,
    rgba(0, 212, 255, 1) 100%
  ) !important;
}
.bg-gray{
  background-color: #F3F3F3;
}
.bg-gray-modal{
  background-color: #2B2B2B !important;
}
.btn-close{
  opacity: 1 !important;
  transition: all .3s ease;
}
.btn-close:hover{
  opacity: .5 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid,
.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: unset;
  padding-right: 10px;
}
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}
input.form-control::-webkit-input-placeholder {
  color: #999999;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
input.form-control::placeholder {
  color: #999999;
  transition: all 0.5s ease;
}

.btn-green {
  background-color: #11819f;
  color: #fff;
  transition: all 0.5s ease;
  padding: 8px 0;
}
.btn-gray {
  background-color: #f0f0f0;
  color: #11819f;
  transition: all 0.5s ease;
  padding: 8px 0;
}

.btn-login {
  background-color: #f0f0f0;
  color: #000;
  /* font-size: 0.8em; */
  transition: all 0.5s ease;
  padding: 3px 35px;
  text-decoration: none;
  border: none;
}
.btn-signup {
  background-color: #11819f;
  color: #fff;
  font-size: 0.8em;
  transition: all 0.5s ease;
}
.btn-cancel {
  background-color: #f0f0f0;
  color: #e97372;
  font-size: 0.8em;
  transition: all 0.5s ease;
}

.btn-green:hover,
.btn-gray:hover,
.btn-login:hover,
.btn-signup:hover,
.btn-cancel:hover {
  background-color: #959595;
  color: #fff;
  transition: all 0.5s ease;
}
.date-name-profile {
  font-size: 12px;
  font-weight: 300;
  transition: all 0.5s ease;
}
.btn-link-default {
  text-decoration: none;
  color: #000;
  font-weight: 300;
}



::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #ffffff;
  /*    border: 0px none #ffffff;*/
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background:#ffffff;
}

::-webkit-scrollbar-thumb:active {
  background: #ffffff
}

::-webkit-scrollbar-track {
  background: #B59E8B;
  /*    border: 0px none #ffffff;*/
  border-radius: 50px;
}
.bg-menu-top{
  background: #1C184A;
  /* border-bottom: 5px solid red; */
  /* border: solid 5px transparent; */
  /* background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(0,30,36,0) 100%); */
}
.line-gra{
 background: rgb(247,175,207);
background: linear-gradient(270deg, rgba(247,175,207,1) 18%, rgba(255,255,255,1) 100%);
bottom: -5px;
    height: 5px;
    width: 100%;
    left: 0;
}
.bg-menu-top-2{
  background: rgb(2,0,36);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}
.box-index {
  /* margin-top: -140px; */
}
.logo-home {
  /* position: absolute; */
  left: 30px;
  top: 30px;
  text-align: left;
}
.logo-home a img{
  width: 250px;
}
.box-text-home {
  position: absolute;
  left: 150px;
  /* padding:90px; */
  bottom: 90px;
  /* transform: translate(0, -50%); */
  /* width: 50%; */
}
.border-blue{
  border-bottom: 5px solid #1C184A;
}


hr.hr-green{
  background-color:#11819F;
  opacity: 0.9;
}
.box-num-time{
  position:absolute;
  bottom: -80px;
}
.box-news{
  margin-top: 120px;
  margin-bottom: 120px;
}
.box-section-youtube{
  margin-top: -120px;
}

/* .font-mn{
  font-family: "Khmer MN";
}
.font-mt{
  font-family: "Bodoni MT";
} */
.num-dis{
  /* font-family: "Khmer MN"; */
  font-size: 200px;
  line-height: 0 !important;
  margin-bottom: -40px;
}
.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active{
  background-color: #2F2F2F !important;
}
.alice-carousel__dots-item:not(.__custom) {
  background-color: #C1C1C1 !important;
  width: 30px !important;
  height: 4px !important;
  border-radius: unset !important;
}
.banner-custom .alice-carousel__dots{
  position: absolute;
  bottom: 30px;
  left: 150px;
  
}
.banner-custom .alice-carousel__dots-item:not(.__custom) {
  background-color: #54595E !important;
}
.banner-custom .alice-carousel__dots-item:not(.__custom):hover,.banner-custom .alice-carousel__dots-item:not(.__custom).__active{
  background-color: #fff !important;
}
.size-per{
  font-size: 100px;
}
ul.menu-top {
  display: table;
}
ul.menu-top li {
  list-style: none;
  display: table-cell;
  padding: 0px 0;
}
ul.menu-top li a {
  padding: 0 15px;
  color: #fff;
  vertical-align: bottom;  
  margin-bottom: 10px;
  /* font-family: "Bodoni MT"; */
}

ul.menu-top2 {
  display: table;
}
ul.menu-top2 li {
  list-style: none;
  display: table-cell;
  padding: 15px 0;
}
ul.menu-top2 li a {
  padding: 0 15px;
  color: #000;
  /* font-family: "Bodoni MT"; */
}
/* ul.menu-top li a:hover,ul.menu-top li a.active {
  text-decoration: underline;
} */
.dp-menu button{
  background: unset;
  box-shadow: unset;
  border: unset;
  margin-bottom: 10px;
  padding-top: 0;
  font-weight: 300;
  vertical-align: bottom;
  padding-bottom: 0;
  font-weight: 300;
}
.dp-menu button:hover,.dp-menu button:focus:not(:focus-visible),.dp-menu .btn:active,.btn-check:active+.btn:focus, .btn-check:checked+.btn:focus, .btn.active:focus, .btn.show:focus, .btn:active:focus{
  background: unset;
  border: unset;
  box-shadow: unset;
}
.dp-menu .dropdown-menu{
  padding: 25px 15px 15px;
  border-radius: 0;
}
.dp-menu .dropdown-item{
  color: #AFAFAF;
    font-size: 14px;
    transition: 0.7s;
    margin-bottom: 7px;
}
.dp-menu .dropdown-item:hover{
  color: #000;
  background: unset;
}
.dp-menu.show button::after{
-webkit-transform: rotate(180deg);
        transform: rotate(180deg);
}

.login-menu button{
  /* background: unset; */
  box-shadow: unset;
  border: unset;
  margin-bottom: 0px;
  /* padding-top: 0; */
  font-weight: 300;
  vertical-align: bottom;
  padding-bottom: 0;
  font-weight: 300;
  background-color: #f0f0f0;
  color: #000;
  padding: 3px 35px;
  border-radius: 0;
}
.login-menu button:hover,.login-menu button:focus,.login-menu button:active:focus{
  background-color: #f0f0f0;
  color: #000;
  font-weight: 300;
}

/* .login-menu button:hover,.login-menu button:focus:not(:focus-visible),.login-menu .btn:active,.btn-check:active+.btn:focus,.login-menu .btn-check:checked+.btn:focus,.login-menu .btn.active:focus,.login-menu .btn.show:focus,.login-menu .btn:active:focus{
  background: unset;
  border: unset;
  box-shadow: unset;
} */
.login-menu .dropdown-menu{
  padding: 15px 0px 15px;
  border-radius: 0;
}
.login-menu .dropdown-item{
  color: #AFAFAF;
    font-size: 14px;
    transition: 0.7s;
    margin-bottom: 0px;
}
.login-menu .dropdown-item:hover{
  color: #000;
  background: unset;
}
.login-menu.show button::after{
-webkit-transform: rotate(180deg);
        transform: rotate(180deg);
}

.menu-top-right{
  position: absolute;
  top: 0;
  right: 30px;
}
.icon-social{
  width: 30px;
}
.un,.un2 {
  display: inline-block;
}

.un::after {
  /* content: '';
  width: 0px;
  height: 1px;
  display: block;
  background: #fff; */
  transition: 300ms;
  margin-top: -4px;
}
.modal-header{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  border-bottom: unset;
}
.modal-content{
  background: unset;
}
.h-100-per-modal{
  height: calc(100vh - 6rem - 65px);
}
.un2::after {
  content: '';
  width: 0px;
  height: 1px;
  display: block;
  background: #000;
  transition: 300ms;
  margin-top: -4px;
}
.btn-blue{
  background-color: #1C184A;
  text-decoration: none;
  color: #fff;
  padding: 5px 60px;
  transition: all .3s ease;
  border: none;
}
.btn-blue:hover{
  opacity: .7;
  color: #fff;
}
.btn-uploadsfile{
  background-color: #60A6DB;
  color: #fff;
  cursor: pointer;
}
.btn-uploadsfile:hover{
  opacity: .7;
  color: #fff;
}
.btn-red{
  background-color: #FF0000;
  text-decoration: none;
  color: #fff;
  padding: 5px 60px;
  transition: all .3s ease;
  border: none;
}
.btn-red:hover{
  opacity: .7;
  color: #fff;
}
.btn-default{
  color: #919191;
  text-decoration: none;
  transition: all .3s ease;
}
.btn-default:hover{
  color: #919191;
  opacity: .7;
}
.a-default{
  color: #000;
  text-decoration: none;
  transition: all .3s ease;
}
.a-white-default{
  color: #fff;
  text-decoration: none;
  transition: all .3s ease;
}
.a-default:hover{
  color: #000;
  text-decoration: underline;
}
.a-white-default:hover{
  opacity: .7;
  color: #fff;
}

.box-detail p{
  margin: 0;
}
.un:hover::after,.un.active::after,.un2:hover::after,.un2.active::after {
  width: 100%;
}
.input-custom{
  background-color: unset;
  border-top: unset;
  border-left: unset;
  border-right: unset;
  border-radius: unset;
  font-style: italic;
  color: #fff;
}
.input-custom-2{
  background-color: unset;
  border-top: unset;
  border-left: unset;
  border-right: unset;
  border-radius: unset;
  /* font-style: italic; */
  /* color: #fff; */
}
.sh-pass{
  position: absolute;
  right: 0;
  bottom: 3px;
}
.hide-password{
  height: 22px;
    border: 1px solid #969696;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    position: absolute;
    top: 1px;
    left: 9px;
}
.register-custom{
  background-color: unset;
  border-top: unset;
  border-left: unset;
  border-right: unset;
  border-radius: unset;
  font-style: italic;
  
  /* color: ; */
}

.form-control.is-invalid, .was-validated .form-control:invalid,.form-control.is-valid, .was-validated .form-control:valid{
  background-image: unset;
}
.was-validated .form-select:valid:not([multiple]):not([size]),.was-validated .form-select:invalid:not([multiple]):not([size]){
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-control:focus{
  
  box-shadow: unset !important;
  border-color: unset;
}
.input-custom:focus{
  background: rgba(255,255,255,0);
  color: #fff;
}
.input-custom-2:focus{
  /* background: rgba(255,255,255,0); */
  /* color: #fff; */
}

.box-footer {
  padding: 30px 90px 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-center {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-top-center {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
.bg-contain{
  background-size: contain;
}
.bg-start {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left top;
}

/* new */
.hbtn {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  padding: 10px 90px;
  margin: 0px 3px 6px;
  text-align: center;
  /* border: 2px solid rgb(255, 255, 255); */
  text-decoration: none;
  color: #070707;
  white-space: nowrap;
  z-index: 0;
  font-weight: 600;
  background: #E8E8E8;
} 
.hbtn i {
  padding-right: 8px;
} 
.hb-fill-right::before {
  position: absolute;
  content: "";
  background: #60A6DB;
  transition-duration: 0.3s;
  z-index: -1;
  inset: 0px auto auto 0px;
  width: 15px;
  height: 100%;
  opacity: 1;
} 
.hb-fill-right:hover::before {
  width: 100%;
  height: 100%;
  opacity: 1;
} 
.hb-fill-right:hover {
  color: #fff;
  background: rgb(255, 255, 255);
  transition: color 0.3s ease 0s, background 0s ease 0.3s;
} 

.alice-carousel__stage-item img{
  padding: 5px !important;
}
.alice-carousel__dots-item:not(.__custom):not(:last-child) {
  margin-right: 3px !important;
}
.alice-carousel__prev-btn, .alice-carousel__next-btn{
  position: absolute;
  width: 0% !important;
  top: 40%;
}
.alice-carousel__prev-btn{
  left: -40px;
}
.alice-carousel__next-btn{
  right: -40px;
}

.banner-custom .alice-carousel__stage-item img{
  padding: 0px !important;
}
.banner-custom .alice-carousel__prev-btn{
  left: 30px;
  width: 0% !important;
}
.banner-custom .alice-carousel__next-btn{
  right: 35px;
  width: 0% !important;
}
.alice-carousel__prev-btn{
  text-align: left !important;
}
.alice-carousel__next-btn{
  text-align: right !important;
}
.cursor-pointer{
  cursor: pointer;
}
.sorn-left,.sorn-right{
  cursor: pointer;
  opacity: 0.3;
  transition: 0.7s;
}
.banner-custom .sorn-left,.banner-custom .sorn-right{
  cursor: pointer;
  opacity: 0.3;
  transition: 0.7s;
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}
.banner-custom .sorn-left:hover,.banner-custom .sorn-right:hover{
  opacity: 1;
}
.banner-custom .sorn-left{
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.sorn-left:hover,.sorn-right:hover{
  opacity: 1;
}
.sorn-left{
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.bg-white-modal{
  background-color: #fff;
}
.btn-check:focus+.btn, .btn:focus{
  box-shadow: unset !important;
}
.number-time{
  font-size: 42px;
}
.text-time{
  font-size: 16px;
}
.mum-time{
  background: rgb(246, 174, 206);
    width: 60px;
    height: 30px;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    right: -19px;
    top: -4px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
.topic-news{
  font-size: 32px;

}
.see-more{
  text-decoration: none;
  transition: 0.7s;
}
.see-more:hover{
  color: #60A6DB;
  opacity: 0.7;
}
.border-loop{
  border-bottom: 1px solid #E4E4E4;
}
.border-loop:last-child{
  border-bottom: 0px solid red;
}
.img-seemore{
  width: 25px;
}
.font-bb{
  font-family: 'Bebas';
}
.day-sum{
  font-size: 62px;
  font-weight: 300;
  line-height: 1 !important;
}
.month-sum{
  font-size: 31px;
  margin-top: -7px;
  display: block;
}
.img-hover{
  display: block;
  width: 100%;
  height: auto;
  position: relative;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0%;
  width: 100%;
  opacity: 0;
  transition: all .3s ease;
  background-color: rgba(0,0,0,0.7);
}
.overlay-text {
  width: 100%;
  color: white;
  font-size: 22px;
  padding: 0 30px;
  position: absolute;
  bottom: 80px;;
  left: 50%;
  -webkit-transform: translate(-50%, -0%);
  transform: translate(-50%, -0%);
  text-align: center;
  /* transition: all .3s ease-out; */
}
.img-hover:hover .overlay{
  opacity: 1;
  height: 100%;
  transition: all .3s ease;
}

.box-youtube{
  position: absolute;
  bottom: 30px;
  width: 100%;
}
.present-height{
  height: 300px;
  overflow-y: auto;
  padding-right: 12px;
}
.btn-seemore{
  color: #fff;
  text-decoration: none;
  background-color: #60A6DB;
  padding: 7px 60px;
  transition: all .3s ease;
}
.btn-seemore:hover{
opacity: 0.7;
color: #fff;
}
.btn-border{
  color: #000;
  text-decoration: none;
  background-color: #fff;
  padding: 5px 0px;
  transition: all .3s ease;
  width: 100%;
  display: block;
  text-align: center;
  border: 1px solid #000;
}
.btn-border:hover{
opacity: 0.7;
color: #000;
}
.box-history{
  /* margin-top:-60px; */
}
.click-page{
  background-color: #FFFFFF;
  text-decoration: none;
  padding: 5px 15px;
  width: 100%;
  color: #000;
  display: block;
}
.click-page:hover{
  color: #1C184A;
}
.box-login-alert{
  background-color: #2B2B2B;
}
.absolute-center{
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}
.absolute-navigation{
  top: 15px;
  -webkit-transform: translate(0, 0%);
          transform: translate(0, 0%);
}
.box-absolute-center{
  top: 50%;
  left: 50%;;
  -webkit-transform: translate(-50%, -50%);;
          transform: translate(-50%, -50%);
}
.box-absolute-bottom{
  position: absolute;
  left: 0%;
  bottom: 0%;
  /* transform: translate(0%,-50%); */
}
.tab-host{
  position:absolute;
  background-color: #2E2E80;
  color: #fff;
  height: 100%;
}
.box-host{
  background-color:rgba(255,255,255,.5)
}
.rotate-90{
  -webkit-writing-mode: vertical-lr;
          writing-mode: vertical-lr;
  -webkit-transform: rotate(-180deg) translate(0,50%);
          transform: rotate(-180deg) translate(0,50%);
  top: 50%;
  left: -40px;
  position: absolute;
  /* width: 120px; */
  background-color: #2E2E80;
  height: 100%;
  text-align: center;
  padding: 8px;
}
.box-gallery-list-a{
  width: 80px;
  height: 80px;
  display: inline-block;
}
.box-gallery-list{
  width: 200px;
  height: 200px;
  display: inline-block;
}
.box-length{
  position: absolute;
  background-color: rgba(0,0,0,0.7);
  color: #fff;
  width: 100%;
  height: 100%;
}
.box-absolute-his{
  position: absolute;
  left: 0%;
  bottom: -35px;
  /* transform: translate(0%,-50%); */
}
.box-absolute-right-center{
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0%,-50%);
          transform: translate(0%,-50%);
}
.gallery-full .alice-carousel__prev-btn{
  top: unset;
  bottom: 0;
  left: 15px;
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}
.gallery-full .alice-carousel__next-btn{
  top: unset;
  bottom: 0;
  left: 40px;
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}
.gallery-full .alice-carousel__stage-item img {
  padding: 0px !important;
  
}
.box-loading{
  z-index: 1;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,.8)
}

/* @import url(https://fonts.googleapis.com/css?family=Raleway:400, 700, 800);
*, */
*:after,
*:before {
  box-sizing: border-box;
}
html {
  height: 100%;
}

#app {
  height: 100%;
}

main {
  height: 100%;
  padding: 3em 2em;
  text-align: center;
  background: #b4bad2;
  overflow: auto;
}

.menu-res {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  background:#1C184A;
    height: 70px;
}
.description {
  max-width: 20em;
  margin: 1.2em auto 1em;
  line-height: 1.3em;
  font-weight: 400;
}
.demo-buttons {
  font-size: 1em;
  max-width: 1200px;
  margin: 2em auto 3em;
  padding: 0 10em;
}
.demo-buttons a {
  display: inline-block;
  margin: 0.75em;
  padding: 1.35em 1.1em;
  width: 15em;
  background: #fffce1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 800;
  border-top-left-radius: 20px 50px;
  border-top-right-radius: 20px 50px;
  border-bottom-right-radius: 20px 50px;
  border-bottom-left-radius: 20px 50px;
  cursor: pointer;
}
.demo-buttons a.current-demo {
  background: #c94e50;
  color: #fffce1;
}
.side-button {
  display: inline-block;
  width: 5em;
  height: 2.5em;
  line-height: 2.5em;
  cursor: pointer;
  background: #fffce1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8em;
  font-weight: 800;
}
.side-button.left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.side-button.right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.side-button.active {
  background: #c94e50;
  color: #fffce1;
}
@media screen and (max-width: 40em) {
  main {
    font-size: 80%;
  }
 
  .demo-buttons {
    max-width: 900px;
    padding: 0 2em;
  }
}
.bm-burger-button {
  position: fixed;
  width: 35px;
  height: 35px;
  left: 36px;
  top: 18px;
  border-radius: 3px;
}
.bm-burger-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 8px;
}
.bm-burger-button button:focus + span span.bm-burger-bars {
  background-color: #c94e50;
}
.right .bm-burger-button {
  left: initial;
  right: 20px;
  background-color: #fff;
}
.bm-burger-bars {
  background: #1C184A;
  height: 3px !important;
  z-index: 9;
  left: 7px !important;
  width: 20px;
}
.bm-burger-button span {
  display: block;
  height: 20px;
  position: relative;
  top: 8px;
}

.bm-morph-shape {
  fill: #373a47;
}
.bm-menu a {
  color: #2F2F2F;
}
.bm-menu a:hover,
.bm-menu a:focus {
  color: #c94e50;
}
.bm-item-list a {
  padding: 0.8em;
}
.bm-item-list a span {
  margin-left: 10px;
  font-weight: 700;
}
.bm-item:focus {
  outline: none;
}
.menu-1 .bm-cross {
  background: #bdc3c7;
}
.menu-1 .bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}
.menu-1 .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}
.menu-1 .bm-cross-button button:focus + span span.bm-cross {
  background-color: #c94e50;
}
.menu-2 .bm-cross {
  background: #999;
}
.menu-2 .bm-menu {
  padding: 3.4em 1em 0;
}
.menu-2 a {
  padding: 1em;
}
.menu-2 i {
  font-size: 1.7em;
  vertical-align: middle;
  color: #282a35;
}
.menu-2 .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}
.menu-2 .bm-cross-button button:focus + span span.bm-cross {
  background-color: #c94e50;
}
.menu-3 .bm-cross {
  background: #888;
}
.menu-3 .bm-menu {
  padding: 2em 1em;
  font-size: 1.15em;
}
.menu-3 i {
  opacity: 0.5;
}
.menu-3 span {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.75em;
}
.menu-3 .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}
.menu-3 .bm-cross-button button:focus + span span.bm-cross {
  background-color: #c94e50;
}
.menu-4 .bm-cross {
  background: #888;
}
.menu-4 h2 {
  margin: 0 auto;
  padding: 2em 1em;
  color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}
.menu-4 h2 i {
  margin-left: 0.2em;
}
.menu-4 h2 span {
  font-size: 1.6em;
  font-weight: 700;
}
.menu-4 a {
  padding: 1em;
  text-transform: uppercase;
  transition: background 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}
.menu-4 a span {
  letter-spacing: 1px;
  font-weight: 400;
}
.menu-4 a:hover,
.menu-4 a:focus {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
  color: #b8b7ad;
}
.menu-4 .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}
.menu-4 .bm-cross-button button:focus + span span.bm-cross {
  background-color: #c94e50;
}
#slide .bm-cross,
#stack .bm-cross,
#reveal .bm-cross {
  background: #bdc3c7;
}
#slide .bm-menu,
#stack .bm-menu,
#reveal .bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}
#slide .bm-cross-button button:focus,
#stack .bm-cross-button button:focus,
#reveal .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}
#slide .bm-cross-button button:focus + span span.bm-cross,
#stack .bm-cross-button button:focus + span span.bm-cross,
#reveal .bm-cross-button button:focus + span span.bm-cross {
  background-color: #c94e50;
}
#bubble .bm-cross,
#push .bm-cross {
  background: #999;
}
#bubble .bm-menu,
#push .bm-menu {
  padding: 3.4em 1em 0;
}
#bubble a,
#push a {
  padding: 1em;
}
#bubble i,
#push i {
  font-size: 1.7em;
  vertical-align: middle;
  color: #282a35;
}
#bubble .bm-cross-button button:focus,
#push .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}
#bubble .bm-cross-button button:focus + span span.bm-cross,
#push .bm-cross-button button:focus + span span.bm-cross {
  background-color: #c94e50;
}
#elastic .bm-cross {
  background: #888;
}
#elastic .bm-menu {
  padding: 2em 1em;
  font-size: 1.15em;
}
#elastic i {
  opacity: 0.5;
}
#elastic span {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.75em;
}
#elastic .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}
#elastic .bm-cross-button button:focus + span span.bm-cross {
  background-color: #c94e50;
}
#scaleDown .bm-cross,
#scaleRotate .bm-cross,
#pushRotate .bm-cross,
#fallDown .bm-cross {
  background: #888;
}
#scaleDown h2,
#scaleRotate h2,
#pushRotate h2,
#fallDown h2 {
  margin: 0 auto;
  padding: 2em 1em;
  color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}
#scaleDown h2 i,
#scaleRotate h2 i,
#pushRotate h2 i,
#fallDown h2 i {
  margin-left: 0.2em;
}
#scaleDown h2 span,
#scaleRotate h2 span,
#pushRotate h2 span,
#fallDown h2 span {
  font-size: 1.6em;
  font-weight: 700;
}
#scaleDown a,
#scaleRotate a,
#pushRotate a,
#fallDown a {
  padding: 1em;
  text-transform: uppercase;
  transition: background 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}
#scaleDown a span,
#scaleRotate a span,
#pushRotate a span,
#fallDown a span {
  letter-spacing: 1px;
  font-weight: 400;
}
#scaleDown a:hover,
#scaleRotate a:hover,
#pushRotate a:hover,
#fallDown a:hover,
#scaleDown a:focus,
#scaleRotate a:focus,
#pushRotate a:focus,
#fallDown a:focus {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
  color: #b8b7ad;
}
#scaleDown .bm-cross-button button:focus,
#scaleRotate .bm-cross-button button:focus,
#pushRotate .bm-cross-button button:focus,
#fallDown .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}
#scaleDown .bm-cross-button button:focus + span span.bm-cross,
#scaleRotate .bm-cross-button button:focus + span span.bm-cross,
#pushRotate .bm-cross-button button:focus + span span.bm-cross,
#fallDown .bm-cross-button button:focus + span span.bm-cross {
  background-color: #c94e50;
}
ul.menu-m {
  padding: 20px 15px 20px 15px;
  margin: 0;
}
ul.menu-m li {
  list-style: none;
  text-align: right;
  padding: 5px 0px 5px 0px;
  border-bottom: 1px solid #E4E2DD;
}
ul.menu-m li a {
  text-decoration: none;
  font-size: 14px;
  padding: 5px 0;
  color: #797979;
  /* font-family: "Bodoni MT"; */
}
ul.menu-m li a:hover {
color: #000;
}
ul.menu-m li:last-child{
  border-bottom: unset;
}
.bg-menu-m{
  /* background-image: url('../img/bg-menu.jpg'); */
  background-color: #F3F3F3;
}

.menu-open .submenu {
display: block;
}
.menu-open ul.submenu li{
  border-bottom: unset;
}
.menu-open .nav-link .arrow90{
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.arrow90{
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
@media screen and (max-width: 1280px) {
	.logo-home a img {
    width: 200px;
    padding: 15px 0;
}
.box-gallery-list {
  width: 150px;
  height: 150px;
}
.dp-menu button{
  font-size: 12px;
}
ul.menu-top li a{
  font-size: 12px;
}
}
@media screen and (max-width: 1024px) {

  .dp-menu button,ul.menu-top li a{
    font-size: 9px;
    padding: 0 5px;
  }
  .logo-home a img {
    width: 120px;
    padding: 30px 0;
}
.box-gallery-list {
  width: 100px;
  height: 100px;
}
.box-gallery-list-a {
  width: 50px;
  height: 50px;
}
	
}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 490px) {
    .wrapper{
        padding: 0px;
    }
    .box-index{
        margin-top: 75px;
    }
    .banner-custom .alice-carousel__prev-btn{
        left: 5px;
    }
    .banner-custom .alice-carousel__next-btn{
        right: 5px;
    }
    .banner-custom .sorn-left,.banner-custom .sorn-right{
        height: 25px;
    }
    .banner-custom .alice-carousel__dots {
        bottom: 0px;
        left: 8px;
    }
    .banner-custom .alice-carousel__dots-item:not(.__custom) {
        width: 20px !important;
        height: 4px !important;
    }
    .form-control{
        border-bottom: 1px solid #1C184A;
    }
    .btn-login{
        background-color: #1C184A;
        color: #fff !important;
        padding: 5px 30px !important;
        display: block;
        width: 100%;
    }
    .box-num-time{
        position: relative;
        bottom: 0px;
        margin-top: 15px;
    }
    .bg-menu-m{
        top: 0;
    }
    .box-news{
        margin: 30px 0;
      }
      .box-section-youtube{
        margin-top: 0px;
      }
      .present-height{
        height: 220px;
      }
      .box-youtube{
        bottom: 100px;
      }
      .box-absolute-res{
        position:relative !important;
      }
      .absolute-center-res{
        top: unset;
        -webkit-transform: unset;
                transform: unset;
      }
      .box-footer {
        padding: 30px 15px 15px;
      }
      .overlay {
        height: 35%;
        opacity: 1;
    }
    .overlay-text{
        bottom: 15px;
    }
    .logo-home a img {
        width: 180px;
    }
    .box-absolute-bottom{
        position: relative;
    }

    .login-menu button{
        background-color: #1C184A;
        color: #fff;
        width: 100%;
      }
      .login-menu button:hover,.login-menu button:focus,.login-menu button:active:focus{
        background-color: #1C184A;
        color: #fff;
      }
      .overlay-text {
        font-size: 14px;
        padding: 0 15px;
      }
      .topic-news {
        font-size: 22px;
    }
    .rotate-90{
      left: -10px;
    }
    .margin-res-0{
      margin-top: 0 !important;
    }
    .box-gallery-list {
      width: 60px;
      height: 60px;
    }
   
}
.ex-checkbox,
.ex-radio {
  position: relative;
  margin: 0;
  font-size: 14px;
}

.ex-checkbox label,
.ex-radio span {
  padding-left: 30px;
  line-height: 140%;
  font-weight: normal;
  display: inline-block;
  position: relative;
  height: 22px;
  margin: 0;
}

.ex-checkbox input[type="checkbox"],
.ex-radio input[type="radio"] {
  margin: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 20px;
  z-index: 10;
  position: absolute;
}

.ex-checkbox input[type="checkbox"] + label::before {
  border: 1px solid #1C184A;
  width: 18px;
  height: 18px;
  content: "";
  position: absolute;
  left: 0;
  background-color: #fff;
  border-radius: 0px;
}

/* .ex-checkbox input[type="checkbox"]:hover + label::before {
  border: 2px solid #abe6cd;
} */

.ex-checkbox input[type="checkbox"]:focus + label::before {
  box-shadow: 0 0 1px #abe6cd;
  outline: none;
}

.ex-checkbox input[type="checkbox"]:checked + label::before {
  /* border: 1px solid #abe6cd; */
  background: url(/static/media/took2.2bf180a2.svg) no-repeat center center;
  background-size: 16px 16px;
}

/* .ex-checkbox input[type="checkbox"]:checked:hover + label::before {
  background-size: 30px 30px;
} */

.ex-checkbox input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

.ex-checkbox input[type="checkbox"]:disabled + label {
  color: #bcbcbc;
  cursor: not-allowed;
}

.ex-checkbox input[type="checkbox"]:disabled:checked + label::before {
  border: 1px solid transparent;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBmaWxsPSIjYmNiY2JjIiBkPSJNMTguOSA4LjhsLTguNyA4LjdjLS4xLjEtLjIuMS0uMyAwbC00LjktNC45Yy0uMS0uMS0uMS0uMiAwLS4zbDEuMi0xLjJjLjEtLjEuMi0uMS4zIDBsMy42IDMuNiA3LjMtNy4zYy4xLS4xLjItLjEuMyAwbDEuMiAxLjJjLjEgMCAuMS4xIDAgLjJ6IiAvPjwvc3ZnPg==")
    no-repeat center center;
}

.ex-checkbox input[type="checkbox"]:disabled + label::before {
  border: 1px solid #bcbcbc;
}

.ex-radio input[type="radio"] + span::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  border: 1px solid #9f9f9f;
  border-radius: 50%;
  background-color: #fff;
  transition: border 0.3s ease-in-out;
}

.ex-radio input[type="radio"]:hover + span::before {
  border: 2px solid #2E384D;
}

.ex-radio input[type="radio"]:focus + span::before {
  box-shadow: 0 0 1px #2E384D;
  outline: none;
}

.ex-radio input[type="radio"]:checked + span::before,
.ex-radio input[type="radio"]:checked:hover + span::before {
  border: 1px solid #2E384D;
}

.ex-radio input[type="radio"] + span::after {
  position: absolute;
  content: " ";
  width: 12px;
  height: 12px;
  left: 4px;
  top: 4px;
  border-radius: 50%;
  background-color: #ffffff;
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.ex-radio input[type="radio"]:checked + span::after {
  background-color: #2E384D;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.ex-radio input[type="radio"]:disabled {
  cursor: not-allowed;
}

.ex-radio input[type="radio"]:disabled + span {
  color: #bcbcbc;
  cursor: not-allowed;
}

.ex-radio input[type="radio"]:disabled + span::before,
.ex-radio input[type="radio"]:checked:disabled + span::before {
  border: 1px solid #bcbcbc;
}

.ex-radio input[type="radio"]:checked:disabled + span::after {
  background-color: #bcbcbc;
}

.ex-inputfield {
  position: relative;
  margin: 16px 0;
}

.ex-inputfield input::-ms-clear,
.ex-inputfield input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.ex-inputfield input[type="text"] {
  -webkit-appearance: none;
  height: 24px;
  padding: 0 8px;
  border: 1px solid #9f9f9f;
  font-size: 14px;
  border-radius: 0;
}

.ex-inputfield input[type="text"].ex-inputfield--large {
  height: 40px;
  padding: 0 16px;
  font-size: 16px;
}

.ex-inputfield input[type="text"]:disabled {
  background-color: #f0f0f0;
  border: 1px solid #bcbcbc;
  color: #757575;
  cursor: not-allowed;
  opacity: 1;
  /*used for safari as it changes it by default to 0.4*/
}

.ex-inputfield input[type="text"]:focus {
  box-shadow: 0 0 1px #abe6cd;
  outline: none;
}

.ex-inputfield input::-webkit-input-placeholder {
  color: #bcbcbc;
}

.ex-inputfield input[type="text"]:-ms-input-placeholder {
  color: #bcbcbc;
}

.ex-inputfield input::-moz-placeholder {
  color: #bcbcbc;
  opacity: 1;
}



.ex-checkbox2,
.ex-radio2 {
  position: relative;
  margin: 0;
  font-size: 14px;
}

.ex-checkbox2 label,
.ex-radio2 span {
  padding-left: 0px;
  line-height: 140%;
  font-weight: normal;
  display: inline-block;
  position: relative;
  /* height: 18px; */
  margin: 0;
  width: 100%;
  background-color: #F0F0F0;
  color: #88B8A4;
  padding: 30px 15px;
  border-radius: 6px;
}

.ex-checkbox2 input[type="checkbox"],
.ex-radio2 input[type="radio"] {
  margin: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 80px;
  z-index: 10;
  left: 0;
  position: absolute;
}

.ex-checkbox2 input[type="checkbox"] + label::before {
  /* border: 1px solid #abe6cd; */
  width: 23px;
  height: 23px;
  content: "";
  position: absolute;
  right: 15px;
  background-color: #fff;
}

/* .ex-checkbox2 input[type="checkbox"]:hover + label::before {
  border: 2px solid #abe6cd;
} */

.ex-checkbox2 input[type="checkbox"]:focus + label::before {
  /* box-shadow: 0 0 1px #abe6cd; */
  outline: none;
}

.ex-checkbox2 input[type="checkbox"]:checked + label {
  background-color: #CCF0E1;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}
.ex-checkbox2 input[type="checkbox"]:checked + label::before {
  /* border: 1px solid #abe6cd; */
  background: url(/static/media/took2.2bf180a2.svg) no-repeat center center;
  background-size: 24px 24px;
}

/* .ex-checkbox2 input[type="checkbox"]:checked:hover + label::before {
  background-size: 30px 30px;
} */

.ex-checkbox2 input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

.ex-checkbox2 input[type="checkbox"]:disabled + label {
  color: #bcbcbc;
  cursor: not-allowed;
}

.ex-checkbox2 input[type="checkbox"]:disabled:checked + label::before {
  border: 1px solid transparent;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBmaWxsPSIjYmNiY2JjIiBkPSJNMTguOSA4LjhsLTguNyA4LjdjLS4xLjEtLjIuMS0uMyAwbC00LjktNC45Yy0uMS0uMS0uMS0uMiAwLS4zbDEuMi0xLjJjLjEtLjEuMi0uMS4zIDBsMy42IDMuNiA3LjMtNy4zYy4xLS4xLjItLjEuMyAwbDEuMiAxLjJjLjEgMCAuMS4xIDAgLjJ6IiAvPjwvc3ZnPg==")
    no-repeat center center;
}

.ex-checkbox2 input[type="checkbox"]:disabled + label::before {
  border: 1px solid #bcbcbc;
}


.ex-checkbox3,
.ex-radio3 {
  position: relative;
  margin: 0;
  font-size: 14px;
}

.ex-checkbox3 label,
.ex-radio3 span {
  padding-left: 0px;
  line-height: 140%;
  font-weight: normal;
  display: inline-block;
  position: relative;
  /* height: 18px; */
  margin: 0;
  width: 100%;
  /* background-color: #F0F0F0; */
  color: #88B8A4;
  padding: 30px 15px;
  border-radius: 6px;
}

.ex-checkbox3 input[type="checkbox"],
.ex-radio3 input[type="radio"] {
  margin: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 150px;
  z-index: 10;
  left: 0;
  position: absolute;
}

.ex-checkbox3 input[type="checkbox"] + label::before {
  /* border: 1px solid #abe6cd; */
  width: 23px;
  height: 23px;
  content: "";
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  border-radius: 3px;
}

/* .ex-checkbox3 input[type="checkbox"]:hover + label::before {
  border: 2px solid #abe6cd;
} */

.ex-checkbox3 input[type="checkbox"]:focus + label::before {
  /* box-shadow: 0 0 1px #abe6cd; */
  outline: none;
}

.ex-checkbox3 input[type="checkbox"]:checked + label {
  /* background-color: #CCF0E1; */
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}
.ex-checkbox3 input[type="checkbox"]:checked + label::before {
  /* border: 1px solid #abe6cd; */
  background: url(/static/media/took2.2bf180a2.svg) no-repeat center center;
  background-size: 24px 24px;
}

/* .ex-checkbox2 input[type="checkbox"]:checked:hover + label::before {
  background-size: 30px 30px;
} */

.ex-checkbox3 input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

.ex-checkbox3 input[type="checkbox"]:disabled + label {
  color: #bcbcbc;
  cursor: not-allowed;
}

.ex-checkbox3 input[type="checkbox"]:disabled:checked + label::before {
  border: 1px solid transparent;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBmaWxsPSIjYmNiY2JjIiBkPSJNMTguOSA4LjhsLTguNyA4LjdjLS4xLjEtLjIuMS0uMyAwbC00LjktNC45Yy0uMS0uMS0uMS0uMiAwLS4zbDEuMi0xLjJjLjEtLjEuMi0uMS4zIDBsMy42IDMuNiA3LjMtNy4zYy4xLS4xLjItLjEuMyAwbDEuMiAxLjJjLjEgMCAuMS4xIDAgLjJ6IiAvPjwvc3ZnPg==")
    no-repeat center center;
}

.ex-checkbox3 input[type="checkbox"]:disabled + label::before {
  border: 1px solid #bcbcbc;
}


.ex-checkbox4,
.ex-radio4 {
  position: relative;
  margin: 0;
  font-size: 16px;
}
.ex-checkbox4 label{
  font-size: 14px;
  padding-left: 0px;
  line-height: 140%;
  font-weight: 400;
  display: inline-block;
  position: relative;
  /* height: 18px; */
  margin: 0;
  width: 100%;
  /* background-color: #F0F0F0; */
  /* color: #88B8A4; */
  /* padding: 30px 15px; */
  border-radius: 6px;
}
.ex-radio4 span {
  padding-left: 0px;
  line-height: 140%;
  font-weight: 500;
  display: inline-block;
  position: relative;
  /* height: 18px; */
  margin: 0;
  width: 100%;
  /* background-color: #F0F0F0; */
  /* color: #88B8A4; */
  /* padding: 30px 15px; */
  border-radius: 6px;
}

.ex-checkbox4 input[type="checkbox"]{
  margin: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 20px;
  z-index: 10;
  left: 0;
  position: absolute;
}
.ex-radio4 input[type="radio"] {
  margin: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 22px;
  z-index: 10;
  left: 0;
  position: absolute;
}
.ex-checkbox4 input[type="checkbox"] + label::before{
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
  left: 0px;
  background-color: #F0F0F0;
  border-radius: 3px;
}
.ex-radio4 input[type="radio"] + label::before {
  border: 1px solid #1C184A;
  width: 18px;
  height: 18px;
  content: "";
  position: absolute;
  left: 0px;
  /* background-color: #F0F0F0; */
}

/* .ex-checkbox4 input[type="checkbox"]:hover + label::before {
  border: 2px solid #abe6cd;
} */

.ex-checkbox4 input[type="checkbox"]:focus + label::before,.ex-radio4 input[type="radio"]:focus + label::before {
  /* box-shadow: 0 0 1px #abe6cd; */
  outline: none;
}

/* .ex-checkbox4 input[type="checkbox"]:checked + label {
  background-color: #CCF0E1;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
} */
.ex-checkbox4 input[type="checkbox"]:checked + label::before,.ex-radio4 input[type="radio"]:checked + label::before {
  /* border: 1px solid #abe6cd; */
  background: url(/static/media/took2.2bf180a2.svg) no-repeat center center;
  background-size: 16px 16px;
}

/* .ex-checkbox4 input[type="checkbox"]:checked:hover + label::before {
  background-size: 30px 30px;
} */

.ex-checkbox4 input[type="checkbox"]:disabled,.ex-radio4 input[type="radio"]:disabled {
  cursor: not-allowed;
}

.ex-checkbox4 input[type="checkbox"]:disabled + label,.ex-radio4 input[type="radio"]:disabled + label {
  color: #bcbcbc;
  cursor: not-allowed;
}

.ex-checkbox4 input[type="checkbox"]:disabled:checked + label::before,.ex-radio4 input[type="radio"]:disabled:checked + label::before {
  border: 1px solid transparent;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBmaWxsPSIjYmNiY2JjIiBkPSJNMTguOSA4LjhsLTguNyA4LjdjLS4xLjEtLjIuMS0uMyAwbC00LjktNC45Yy0uMS0uMS0uMS0uMiAwLS4zbDEuMi0xLjJjLjEtLjEuMi0uMS4zIDBsMy42IDMuNiA3LjMtNy4zYy4xLS4xLjItLjEuMyAwbDEuMiAxLjJjLjEgMCAuMS4xIDAgLjJ6IiAvPjwvc3ZnPg==")
    no-repeat center center;
}

.ex-checkbox4 input[type="checkbox"]:disabled + label::before,.ex-radio4 input[type="radio"]:disabled + label::before {
  border: 1px solid #bcbcbc;
}



.forform {
  display: block;
  position: relative;
  /* padding-left: 35px; */
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
}

.forform input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.forform .checkmark {
  /* position: absolute; */
  display: block;
  width: 100%;
  padding: 12px 12px;
  font-size: 14px;
  text-align: center;
  border: 1px solid #F0F0F0;
  background-color: #F0F0F0;
  color: #88B8A4;
  border-radius: 4px;
  font-weight: 300;
  margin-bottom: 8px;
}

.forform input:checked~.checkmark {
  background-color: #88B8A4;
  color: #fff;
}

.forform input:disabled {
  opacity: 0
}

.forform input:disabled~.checkmark {
  color: rgba(0, 0, 0, .26);
  cursor: no-drop;
}

.forform2 {
  display: block;
  position: relative;
  /* padding-left: 35px; */
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
}

.forform2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.forform2 .checkmark {
  /* position: absolute; */
  display: block;
  width: 100%;
  /* padding: 12px 12px; */
  font-size: 16px;
  text-align: center;
  /* border: 1px solid #F0F0F0; */
  /* background-color: #F0F0F0; */
  color: #D8D8D8;
  font-weight: 400;
  border-radius: 4px;
  margin-bottom: 8px;
}

.forform2 .checkmark img{
  -webkit-filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(
    207deg) brightness(95%) contrast(96%);
          filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(
    207deg) brightness(95%) contrast(96%);
}

.forform2 input:checked~.checkmark {
  /* background-color: #88B8A4; */
  color: #242424;
  font-weight: 500;
}
.forform2 input:checked~.checkmark img{
  -webkit-filter: unset;
          filter: unset;
}

.forform2 input:disabled {
  opacity: 0
}

.forform2 input:disabled~.checkmark {
  color: rgba(0, 0, 0, .26);
  cursor: no-drop;
}



.item-renderer{
  position: relative;
  margin: 0;
  font-size: 14px;
}

.item-renderer span {
  padding-left: 30px;
  line-height: 140%;
  font-weight: normal;
  display: inline-block;
  position: relative;
  height: 22px;
  margin: 0;
}

.item-renderer input[type="checkbox"]{
  margin: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 23px;
  z-index: 10;
  position: absolute;
}

.item-renderer input[type="checkbox"] + span::before {
  border: 1px solid #DDDEDD;
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
  left: 0;
  background-color: #fff;
  border-radius: 5px;
}

/* .ex-checkbox input[type="checkbox"]:hover + label::before {
  border: 2px solid #abe6cd;
} */

.item-renderer input[type="checkbox"]:focus + span::before {
  box-shadow: 0 0 1px #abe6cd;
  outline: none;
}

.item-renderer input[type="checkbox"]:checked + span::before {
  border: 1px solid #abe6cd;
  background: url(/static/media/took2.2bf180a2.svg) no-repeat center center;
  background-size: 20px 20px;
}

/* .ex-checkbox input[type="checkbox"]:checked:hover + label::before {
  background-size: 30px 30px;
} */

.item-renderer input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

.item-renderer input[type="checkbox"]:disabled + span {
  color: #bcbcbc;
  cursor: not-allowed;
}

.item-renderer input[type="checkbox"]:disabled:checked + span::before {
  border: 1px solid transparent;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBmaWxsPSIjYmNiY2JjIiBkPSJNMTguOSA4LjhsLTguNyA4LjdjLS4xLjEtLjIuMS0uMyAwbC00LjktNC45Yy0uMS0uMS0uMS0uMiAwLS4zbDEuMi0xLjJjLjEtLjEuMi0uMS4zIDBsMy42IDMuNiA3LjMtNy4zYy4xLS4xLjItLjEuMyAwbDEuMiAxLjJjLjEgMCAuMS4xIDAgLjJ6IiAvPjwvc3ZnPg==")
    no-repeat center center;
}

.item-renderer input[type="checkbox"]:disabled + span::before {
  border: 1px solid #bcbcbc;
}
.rmsc .select-item{
  padding: 2px 10px !important;
}
.rmsc .options{
  padding: 10px 0;
}
.rmsc .select-item.selected{
  background: unset !important;
}

.accordion-button:focus{
  border-color: #E6E6E6;
  box-shadow: unset;
}
.accordion-button:not(.collapsed){
  color: unset;
  background-color: unset;
  box-shadow: unset;
}
/* sort-down.svg */
/* .accordion-button:not(.collapsed)::after,.accordion-button::after{
  background-image: url("../img/sort-down.svg");
  background-size: 12px;
  height: 8px;
  width: 12px;
}
.accordion-item{
  border-top: unset;
  border-left: unset;
  border-right: unset;
  border-bottom: 1px solid #E6E6E6;
}
.accordion-item:last-child{
  border-bottom: unset;
}
.accordion-item .accordion-button{
  padding: 20px 0;
} */


.ex-checkbox-chat,
.ex-radio-chat {
  position: relative;
  margin: 0;
  font-size: 14px;
}

.ex-checkbox-chat label,
.ex-radio-chat span {
  padding-left: 30px;
  line-height: 140%;
  font-weight: normal;
  display: inline-block;
  position: relative;
  height: 22px;
  margin: 0;
}

.ex-checkbox-chat input[type="checkbox"],
.ex-radio-chat input[type="radio"] {
  margin: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 38px;
  z-index: 10;
  position: absolute;
}

.ex-checkbox-chat input[type="checkbox"] + label::before {
  border: 1px solid #DDDEDD;
  width: 23px;
  height: 23px;
  content: "";
  position: absolute;
  left: 0;
  background-color: #fff;
  border-radius: 5px;
  top: 10px;
}
.ex-checkbox-chat input[type="checkbox"]:focus + label::before {
  box-shadow: 0 0 1px #abe6cd;
  outline: none;
}

.ex-checkbox-chat input[type="checkbox"]:checked + label::before {
  border: 1px solid #abe6cd;
  background: url(/static/media/took2.2bf180a2.svg) no-repeat center center;
  background-size: 21px 21px;
}

.ex-checkbox-chat input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

.ex-checkbox-chat input[type="checkbox"]:disabled + label {
  color: #bcbcbc;
  cursor: not-allowed;
}

.ex-checkbox-chat input[type="checkbox"]:disabled:checked + label::before {
  border: 1px solid transparent;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBmaWxsPSIjYmNiY2JjIiBkPSJNMTguOSA4LjhsLTguNyA4LjdjLS4xLjEtLjIuMS0uMyAwbC00LjktNC45Yy0uMS0uMS0uMS0uMiAwLS4zbDEuMi0xLjJjLjEtLjEuMi0uMS4zIDBsMy42IDMuNiA3LjMtNy4zYy4xLS4xLjItLjEuMyAwbDEuMiAxLjJjLjEgMCAuMS4xIDAgLjJ6IiAvPjwvc3ZnPg==")
    no-repeat center center;
}

.ex-checkbox-chat input[type="checkbox"]:disabled + label::before {
  border: 1px solid #bcbcbc;
}


.form-switch .form-check-input {
  width: 40px;
}
.form-check-input {
  height: 22px;
  cursor: pointer;
}
.form-check-input:focus {
  border-color: #EAEAEA;
  box-shadow: unset;
}
.form-switch .form-check-input:focus {
  background-color: #EAEAEA;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-color: #008319;
  border-color: #008319;
  border: none;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
}

fieldset {
  margin: 0;
  box-sizing: border-box;
  display: block;
  border: none;
  min-width: 0;
  background-color: #FFF;
}

body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}

/* TOGGLE STYLING */
.toggle {
  margin: 0 0 1.5rem;
  box-sizing: border-box;
  font-size: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  box-shadow: -10px -5px 8px 4px rgb(173, 173,173,.7);
}
.toggle input {
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
}
.toggle input + label {
  margin: 0;
  padding: 0.75rem 2rem;
  box-sizing: border-box;
  position: relative;
  width: 50%;
    display: inline-block;
  /* border: solid 1px #DDD; */
 
  background-color: #FFF;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  /* box-shadow: 0 0 0 rgba(255, 255, 255, 0); */
  transition: border-color 0.15s ease-out, color 0.25s ease-out, background-color 0.15s ease-out, box-shadow 0.15s ease-out;
  /* ADD THESE PROPERTIES TO SWITCH FROM AUTO WIDTH TO FULL WIDTH */
  /*flex: 0 0 50%; display: flex; justify-content: center; align-items: center;*/
  /* ----- */
}
.toggle input + label:first-of-type {
  /* border-radius: 6px 0 0 6px; */
  border-right: none;
  border-left: none;
  border-top: none;
}
.toggle input + label:last-of-type {
  /* border-radius: 0 6px 6px 0; */
  border-left: none;
  border-right: none;
  border-top: none;
}
.toggle input:hover + label {
  border-color: #213140;
}
.toggle input:checked + label {
  /* background-color: #4B9DEA; */
  /* color: #FFF; */
  /* box-shadow: 0 0 10px rgba(102, 179, 251, 0.5); */
  border-bottom:3px solid #2E384D;
  z-index: 1;
}
.toggle input:focus + label {
  /* outline: dotted 1px #CCC; */
  outline-offset: 0.45rem;
}
.a-select-answer{
  color: #000000;
  text-decoration: none;
}
.a-select-answer:hover{
  color: #fff;
}
.a-select-answer:hover .bg-select-answer{
  background-color: #1C184A;
}
.a-select-answer:hover .bg-select-answer img{
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}
.bg-select-answer img{
  transition: all .3s ease;
}
.bg-select-answer{
  background-color: #fff;
  padding: 15px;
  transition: all .3s ease;
}
@media (max-width: 800px) {
  .toggle input + label {
    padding: 0.75rem 0.25rem;
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


