@import url("../font/stylesheet.css");
::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #ffffff;
  /*    border: 0px none #ffffff;*/
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background:#ffffff;
}

::-webkit-scrollbar-thumb:active {
  background: #ffffff
}

::-webkit-scrollbar-track {
  background: #B59E8B;
  /*    border: 0px none #ffffff;*/
  border-radius: 50px;
}
.bg-menu-top{
  background: #1C184A;
  /* border-bottom: 5px solid red; */
  /* border: solid 5px transparent; */
  /* background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(0,30,36,0) 100%); */
}
.line-gra{
 background: rgb(247,175,207);
background: linear-gradient(270deg, rgba(247,175,207,1) 18%, rgba(255,255,255,1) 100%);
bottom: -5px;
    height: 5px;
    width: 100%;
    left: 0;
}
.bg-menu-top-2{
  background: rgb(2,0,36);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}
.box-index {
  /* margin-top: -140px; */
}
.logo-home {
  /* position: absolute; */
  left: 30px;
  top: 30px;
  text-align: left;
}
.logo-home a img{
  width: 250px;
}
.box-text-home {
  position: absolute;
  left: 150px;
  /* padding:90px; */
  bottom: 90px;
  /* transform: translate(0, -50%); */
  /* width: 50%; */
}
.border-blue{
  border-bottom: 5px solid #1C184A;
}


hr.hr-green{
  background-color:#11819F;
  opacity: 0.9;
}
.box-num-time{
  position:absolute;
  bottom: -80px;
}
.box-news{
  margin-top: 120px;
  margin-bottom: 120px;
}
.box-section-youtube{
  margin-top: -120px;
}

/* .font-mn{
  font-family: "Khmer MN";
}
.font-mt{
  font-family: "Bodoni MT";
} */
.num-dis{
  /* font-family: "Khmer MN"; */
  font-size: 200px;
  line-height: 0 !important;
  margin-bottom: -40px;
}
.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active{
  background-color: #2F2F2F !important;
}
.alice-carousel__dots-item:not(.__custom) {
  background-color: #C1C1C1 !important;
  width: 30px !important;
  height: 4px !important;
  border-radius: unset !important;
}
.banner-custom .alice-carousel__dots{
  position: absolute;
  bottom: 30px;
  left: 150px;
  
}
.banner-custom .alice-carousel__dots-item:not(.__custom) {
  background-color: #54595E !important;
}
.banner-custom .alice-carousel__dots-item:not(.__custom):hover,.banner-custom .alice-carousel__dots-item:not(.__custom).__active{
  background-color: #fff !important;
}
.size-per{
  font-size: 100px;
}
ul.menu-top {
  display: table;
}
ul.menu-top li {
  list-style: none;
  display: table-cell;
  padding: 0px 0;
}
ul.menu-top li a {
  padding: 0 15px;
  color: #fff;
  vertical-align: bottom;  
  margin-bottom: 10px;
  /* font-family: "Bodoni MT"; */
}

ul.menu-top2 {
  display: table;
}
ul.menu-top2 li {
  list-style: none;
  display: table-cell;
  padding: 15px 0;
}
ul.menu-top2 li a {
  padding: 0 15px;
  color: #000;
  /* font-family: "Bodoni MT"; */
}
/* ul.menu-top li a:hover,ul.menu-top li a.active {
  text-decoration: underline;
} */
.dp-menu button{
  background: unset;
  box-shadow: unset;
  border: unset;
  margin-bottom: 10px;
  padding-top: 0;
  font-weight: 300;
  vertical-align: bottom;
  padding-bottom: 0;
  font-weight: 300;
}
.dp-menu button:hover,.dp-menu button:focus:not(:focus-visible),.dp-menu .btn:active,.btn-check:active+.btn:focus, .btn-check:checked+.btn:focus, .btn.active:focus, .btn.show:focus, .btn:active:focus{
  background: unset;
  border: unset;
  box-shadow: unset;
}
.dp-menu .dropdown-menu{
  padding: 25px 15px 15px;
  border-radius: 0;
}
.dp-menu .dropdown-item{
  color: #AFAFAF;
    font-size: 14px;
    transition: 0.7s;
    margin-bottom: 7px;
}
.dp-menu .dropdown-item:hover{
  color: #000;
  background: unset;
}
.dp-menu.show button::after{
transform: rotate(180deg);
}

.login-menu button{
  /* background: unset; */
  box-shadow: unset;
  border: unset;
  margin-bottom: 0px;
  /* padding-top: 0; */
  font-weight: 300;
  vertical-align: bottom;
  padding-bottom: 0;
  font-weight: 300;
  background-color: #f0f0f0;
  color: #000;
  padding: 3px 35px;
  border-radius: 0;
}
.login-menu button:hover,.login-menu button:focus,.login-menu button:active:focus{
  background-color: #f0f0f0;
  color: #000;
  font-weight: 300;
}

/* .login-menu button:hover,.login-menu button:focus:not(:focus-visible),.login-menu .btn:active,.btn-check:active+.btn:focus,.login-menu .btn-check:checked+.btn:focus,.login-menu .btn.active:focus,.login-menu .btn.show:focus,.login-menu .btn:active:focus{
  background: unset;
  border: unset;
  box-shadow: unset;
} */
.login-menu .dropdown-menu{
  padding: 15px 0px 15px;
  border-radius: 0;
}
.login-menu .dropdown-item{
  color: #AFAFAF;
    font-size: 14px;
    transition: 0.7s;
    margin-bottom: 0px;
}
.login-menu .dropdown-item:hover{
  color: #000;
  background: unset;
}
.login-menu.show button::after{
transform: rotate(180deg);
}

.menu-top-right{
  position: absolute;
  top: 0;
  right: 30px;
}
.icon-social{
  width: 30px;
}
.un,.un2 {
  display: inline-block;
}

.un::after {
  /* content: '';
  width: 0px;
  height: 1px;
  display: block;
  background: #fff; */
  transition: 300ms;
  margin-top: -4px;
}
.modal-header{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  border-bottom: unset;
}
.modal-content{
  background: unset;
}
.h-100-per-modal{
  height: calc(100vh - 6rem - 65px);
}
.un2::after {
  content: '';
  width: 0px;
  height: 1px;
  display: block;
  background: #000;
  transition: 300ms;
  margin-top: -4px;
}
.btn-blue{
  background-color: #1C184A;
  text-decoration: none;
  color: #fff;
  padding: 5px 60px;
  transition: all .3s ease;
  border: none;
}
.btn-blue:hover{
  opacity: .7;
  color: #fff;
}
.btn-uploadsfile{
  background-color: #60A6DB;
  color: #fff;
  cursor: pointer;
}
.btn-uploadsfile:hover{
  opacity: .7;
  color: #fff;
}
.btn-red{
  background-color: #FF0000;
  text-decoration: none;
  color: #fff;
  padding: 5px 60px;
  transition: all .3s ease;
  border: none;
}
.btn-red:hover{
  opacity: .7;
  color: #fff;
}
.btn-default{
  color: #919191;
  text-decoration: none;
  transition: all .3s ease;
}
.btn-default:hover{
  color: #919191;
  opacity: .7;
}
.a-default{
  color: #000;
  text-decoration: none;
  transition: all .3s ease;
}
.a-white-default{
  color: #fff;
  text-decoration: none;
  transition: all .3s ease;
}
.a-default:hover{
  color: #000;
  text-decoration: underline;
}
.a-white-default:hover{
  opacity: .7;
  color: #fff;
}

.box-detail p{
  margin: 0;
}
.un:hover::after,.un.active::after,.un2:hover::after,.un2.active::after {
  width: 100%;
}
.input-custom{
  background-color: unset;
  border-top: unset;
  border-left: unset;
  border-right: unset;
  border-radius: unset;
  font-style: italic;
  color: #fff;
}
.input-custom-2{
  background-color: unset;
  border-top: unset;
  border-left: unset;
  border-right: unset;
  border-radius: unset;
  /* font-style: italic; */
  /* color: #fff; */
}
.sh-pass{
  position: absolute;
  right: 0;
  bottom: 3px;
}
.hide-password{
  height: 22px;
    border: 1px solid #969696;
    transform: rotate(-45deg);
    position: absolute;
    top: 1px;
    left: 9px;
}
.register-custom{
  background-color: unset;
  border-top: unset;
  border-left: unset;
  border-right: unset;
  border-radius: unset;
  font-style: italic;
  
  /* color: ; */
}

.form-control.is-invalid, .was-validated .form-control:invalid,.form-control.is-valid, .was-validated .form-control:valid{
  background-image: unset;
}
.was-validated .form-select:valid:not([multiple]):not([size]),.was-validated .form-select:invalid:not([multiple]):not([size]){
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-control:focus{
  
  box-shadow: unset !important;
  border-color: unset;
}
.input-custom:focus{
  background: rgba(255,255,255,0);
  color: #fff;
}
.input-custom-2:focus{
  /* background: rgba(255,255,255,0); */
  /* color: #fff; */
}

.box-footer {
  padding: 30px 90px 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-center {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-top-center {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
.bg-contain{
  background-size: contain;
}
.bg-start {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left top;
}

/* new */
.hbtn {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  padding: 10px 90px;
  margin: 0px 3px 6px;
  text-align: center;
  /* border: 2px solid rgb(255, 255, 255); */
  text-decoration: none;
  color: #070707;
  white-space: nowrap;
  z-index: 0;
  font-weight: 600;
  background: #E8E8E8;
} 
.hbtn i {
  padding-right: 8px;
} 
.hb-fill-right::before {
  position: absolute;
  content: "";
  background: #60A6DB;
  transition-duration: 0.3s;
  z-index: -1;
  inset: 0px auto auto 0px;
  width: 15px;
  height: 100%;
  opacity: 1;
} 
.hb-fill-right:hover::before {
  width: 100%;
  height: 100%;
  opacity: 1;
} 
.hb-fill-right:hover {
  color: #fff;
  background: rgb(255, 255, 255);
  transition: color 0.3s ease 0s, background 0s ease 0.3s;
} 

.alice-carousel__stage-item img{
  padding: 5px !important;
}
.alice-carousel__dots-item:not(.__custom):not(:last-child) {
  margin-right: 3px !important;
}
.alice-carousel__prev-btn, .alice-carousel__next-btn{
  position: absolute;
  width: 0% !important;
  top: 40%;
}
.alice-carousel__prev-btn{
  left: -40px;
}
.alice-carousel__next-btn{
  right: -40px;
}

.banner-custom .alice-carousel__stage-item img{
  padding: 0px !important;
}
.banner-custom .alice-carousel__prev-btn{
  left: 30px;
  width: 0% !important;
}
.banner-custom .alice-carousel__next-btn{
  right: 35px;
  width: 0% !important;
}
.alice-carousel__prev-btn{
  text-align: left !important;
}
.alice-carousel__next-btn{
  text-align: right !important;
}
.cursor-pointer{
  cursor: pointer;
}
.sorn-left,.sorn-right{
  cursor: pointer;
  opacity: 0.3;
  transition: 0.7s;
}
.banner-custom .sorn-left,.banner-custom .sorn-right{
  cursor: pointer;
  opacity: 0.3;
  transition: 0.7s;
  filter: brightness(0) invert(1);
}
.banner-custom .sorn-left:hover,.banner-custom .sorn-right:hover{
  opacity: 1;
}
.banner-custom .sorn-left{
  transform: rotate(180deg);
}
.sorn-left:hover,.sorn-right:hover{
  opacity: 1;
}
.sorn-left{
  transform: rotate(180deg);
}

.bg-white-modal{
  background-color: #fff;
}
.btn-check:focus+.btn, .btn:focus{
  box-shadow: unset !important;
}
.number-time{
  font-size: 42px;
}
.text-time{
  font-size: 16px;
}
.mum-time{
  background: rgb(246, 174, 206);
    width: 60px;
    height: 30px;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    right: -19px;
    top: -4px;
    transform: rotate(45deg);
}
.topic-news{
  font-size: 32px;

}
.see-more{
  text-decoration: none;
  transition: 0.7s;
}
.see-more:hover{
  color: #60A6DB;
  opacity: 0.7;
}
.border-loop{
  border-bottom: 1px solid #E4E4E4;
}
.border-loop:last-child{
  border-bottom: 0px solid red;
}
.img-seemore{
  width: 25px;
}
.font-bb{
  font-family: 'Bebas';
}
.day-sum{
  font-size: 62px;
  font-weight: 300;
  line-height: 1 !important;
}
.month-sum{
  font-size: 31px;
  margin-top: -7px;
  display: block;
}
.img-hover{
  display: block;
  width: 100%;
  height: auto;
  position: relative;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0%;
  width: 100%;
  opacity: 0;
  transition: all .3s ease;
  background-color: rgba(0,0,0,0.7);
}
.overlay-text {
  width: 100%;
  color: white;
  font-size: 22px;
  padding: 0 30px;
  position: absolute;
  bottom: 80px;;
  left: 50%;
  -webkit-transform: translate(-50%, -0%);
  -ms-transform: translate(-50%, -0%);
  transform: translate(-50%, -0%);
  text-align: center;
  /* transition: all .3s ease-out; */
}
.img-hover:hover .overlay{
  opacity: 1;
  height: 100%;
  transition: all .3s ease;
}

.box-youtube{
  position: absolute;
  bottom: 30px;
  width: 100%;
}
.present-height{
  height: 300px;
  overflow-y: auto;
  padding-right: 12px;
}
.btn-seemore{
  color: #fff;
  text-decoration: none;
  background-color: #60A6DB;
  padding: 7px 60px;
  transition: all .3s ease;
}
.btn-seemore:hover{
opacity: 0.7;
color: #fff;
}
.btn-border{
  color: #000;
  text-decoration: none;
  background-color: #fff;
  padding: 5px 0px;
  transition: all .3s ease;
  width: 100%;
  display: block;
  text-align: center;
  border: 1px solid #000;
}
.btn-border:hover{
opacity: 0.7;
color: #000;
}
.box-history{
  /* margin-top:-60px; */
}
.click-page{
  background-color: #FFFFFF;
  text-decoration: none;
  padding: 5px 15px;
  width: 100%;
  color: #000;
  display: block;
}
.click-page:hover{
  color: #1C184A;
}
.box-login-alert{
  background-color: #2B2B2B;
}
.absolute-center{
  top: 50%;
  transform: translate(0, -50%);
}
.absolute-navigation{
  top: 15px;
  transform: translate(0, 0%);
}
.box-absolute-center{
  top: 50%;
  left: 50%;;
  transform: translate(-50%, -50%);
}
.box-absolute-bottom{
  position: absolute;
  left: 0%;
  bottom: 0%;
  /* transform: translate(0%,-50%); */
}
.tab-host{
  position:absolute;
  background-color: #2E2E80;
  color: #fff;
  height: 100%;
}
.box-host{
  background-color:rgba(255,255,255,.5)
}
.rotate-90{
  writing-mode: vertical-lr;
  transform: rotate(-180deg) translate(0,50%);
  top: 50%;
  left: -40px;
  position: absolute;
  /* width: 120px; */
  background-color: #2E2E80;
  height: 100%;
  text-align: center;
  padding: 8px;
}
.box-gallery-list-a{
  width: 80px;
  height: 80px;
  display: inline-block;
}
.box-gallery-list{
  width: 200px;
  height: 200px;
  display: inline-block;
}
.box-length{
  position: absolute;
  background-color: rgba(0,0,0,0.7);
  color: #fff;
  width: 100%;
  height: 100%;
}
.box-absolute-his{
  position: absolute;
  left: 0%;
  bottom: -35px;
  /* transform: translate(0%,-50%); */
}
.box-absolute-right-center{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0%,-50%);
}
.gallery-full .alice-carousel__prev-btn{
  top: unset;
  bottom: 0;
  left: 15px;
  filter: brightness(0) invert(1);
}
.gallery-full .alice-carousel__next-btn{
  top: unset;
  bottom: 0;
  left: 40px;
  filter: brightness(0) invert(1);
}
.gallery-full .alice-carousel__stage-item img {
  padding: 0px !important;
  
}
.box-loading{
  z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,.8)
}
